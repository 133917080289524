
import { Component } from 'vue-property-decorator';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import StackedForm from '../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { Permission } from '../../enums/Permission';

@Component({
  components: { VFormBuilder },
})
export default class RoleForm extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get items() {
    return [
      { name: 'name', type: InputType.Text, label: 'role.form.name', rules: 'required' },
      { name: 'slug', type: InputType.Text, label: 'role.form.slug', rules: 'required' },
      {
        name: 'permissions',
        type: InputType.Autocomplete,
        label: 'role.form.permissions',
        multiple: true,
        rules: 'required',
        items: this.permissions,
      },
      { name: 'isSystem', type: InputType.Checkbox, label: 'role.form.isSystem' },
    ];
  }

  get permissions() {
    return Object.values(Permission)
      .sort()
      .map((p: Permission) => {
        return {
          text: p.toString(),
          value: p.toString(),
        };
      });
  }
}
